<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <router-link key="collapse" class="sidebar-logo-link" to="/">
      <img :src="logoSquare" class="sidebar-logo" />
      <h1 class="sidebar-title">{{ title }}</h1>
    </router-link>
    <!-- <router-link v-else key="expand" class="sidebar-logo-link" to="/">
      <img v-if="logo" :src="logo" class="sidebar-logo" />
      <h1 v-else class="sidebar-title">{{ title }}</h1>
    </router-link> -->
  </div>
</template>

<script>
import logo from '@/assets/logoWhite.png'
import logoSquare from '@/assets/logoWhiteSquare.png'

export default {
  name: 'SidebarLogo',

  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      logo,
      logoSquare,
      title: '第一食品'
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      vertical-align: middle;
      margin-left: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0 10px;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    text-align: center;
    .sidebar-logo {
      width: 21px;
      height: 21px;
      // 对齐展开的图标
      margin-left: 4px;
      margin-bottom: 1px;
    }
  }
}
</style>
