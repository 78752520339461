<template>
  <el-dialog
    :title="isNewUser ? '新用户修改密码' : '修改密码'"
    :visible.sync="visible"
    :show-close="!isNewUser"
    :close-on-click-modal="!isNewUser"
    append-to-body
    @close="closeDialog"
  >
  <div style="margin-bottom: 10px; color: #606266">tips: 新密码需要全部包含大写字母、小写字母、数字、特殊字符，且长度不可少于8位</div>
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="rule-form"
    >
      <el-form-item
        label="旧密码"
        prop="oldPass"
        v-if="!this.$store.state.user.needpsd"
      >
        <el-input
          type="password"
          v-model="ruleForm.oldPass"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="pass">
        <el-input
          type="password"
          v-model="ruleForm.pass"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm()">重置</el-button>
      <el-button v-if="!isNewUser" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="submitForm()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import API from '@/api'

export default {
  name: 'ChangePassDialog',

  props: {
    visible: {
      type: Boolean,
    },
    isNewUser: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const validatePass = (rule, value, callback) => {
      //   let pattern = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![,\.#%'\+\*\-:;^_`]+$)[,\.#%'\+\*\-:;^_`0-9A-Za-z]{8,20}$/
      // let pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/
      let pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*([^(0-9a-zA-Z)])).{8,20}$/
      console.log(pattern.test(value))

      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 8) {
        callback(new Error('至少输入8位'))
      } else if (pattern.test(value) === false) {
        callback(
          new Error(
            '新密码过于简单，需要全部包含大写字母、小写字母、数字、特殊字符，且长度不可少于8位'
          )
        )
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    // const validatePass = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入密码'))
    //   } else if (value.length < 6) {
    //     callback(new Error('至少输入6位'))
    //   } else {
    //     if (this.ruleForm.checkPass !== '') {
    //       this.$refs.ruleForm.validateField('checkPass')
    //     }
    //     callback()
    //   }
    // }

    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次密码不匹配，请重新输入'))
      } else {
        callback()
      }
    }

    return {
      ruleForm: {
        oldPass: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validateCheckPass, trigger: 'blur' }],
      },
    }
  },

  methods: {
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          API.changePassword(this.ruleForm.oldPass, this.ruleForm.pass).then(
            async () => {
              this.closeDialog()
              await this.$store.dispatch('logout')
              this.$router.push('/login')
              this.$message.success('成功修改密码')
            }
          )
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.resetFields()
    },
    closeDialog() {
      this.$refs.ruleForm.resetFields()
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
