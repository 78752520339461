import Cookies from 'js-cookie'
import * as types from './mutation-types'
import createComponent from './create-component'
import { Notification } from 'element-ui'
import { isOnlyOneSlot } from '@editor/utils'
import { clone, cloneDeep, set } from 'lodash-es'
import Vue from 'vue'
import { constantRoutes } from '@/router'

const uuidv1 = require('uuid/v1')

const generatePath = (uuid, map, source) => {
  source.unshift(uuid)
  const parentUUID = map[uuid].parentUUID
  if (parentUUID) {
    generatePath(parentUUID, map, source)
  }
}

const generateChildComponentsData = (children, parentUUID, parentPath) => {
  return children.map((item, index) => {
    let childComponent = createComponent(item)

    childComponent.parentUUID = parentUUID
    childComponent.path = parentPath.concat(childComponent.uuid)

    return childComponent
  })
}

const reCalcPath = (children, parentPath, count = 0) => {
  count += 1
  const onlyOneSlot = isOnlyOneSlot(children)
  if (onlyOneSlot) {
    children.forEach(c => {
      c.path = parentPath.concat(c.path.slice(-count))
      if (c._children) reCalcPath(c._children, parentPath, count)
    })
  } else {
    Object.keys(children).forEach(slotName => {
      children[slotName].forEach(c => {
        c.path = parentPath.concat(c.path.slice(-count))
        if (c._children) reCalcPath(c._children, parentPath, count)
      })
    })
  }
}

export default {
  [types.SET_USER](state, user) {
    state.user = user
  },

  [types.SET_BRANDS](state, brands) {
    state.brands = brands
  },

  [types.SET_LOGIN](state, login) {
    state.login = login
  },

  [types.SET_BRAND](state, userId) {
    state.brands.forEach(v => {
      Vue.set(v, 'isChoosed', (v.USERID === userId))
    })

    window.localStorage.setItem('brands', JSON.stringify(state.brands))
  },

  [types.SET_PROJECT_TREE](state, data) {
    state.projectTree = data
  },

  [types.SET_PROJECT_SETTING](state, setting) {
    setting.components.sideNav.forEach(folder => {
      folder.path = `/${folder.label}`
      folder.pages.forEach(page => {
        page.path = `/${folder.label}/${page.label}`
      })
    })
    state.projectSetting = setting
  },

  [types.SET_PROJECT_URL_MAP_TEMPLATE](state, urlMap) {
    state.projectUrlMap = urlMap
  },

  [types.MODIFY_PAGE_CONFIGS](state, configs) {
    const { currentFile, pageMapping } = state
    Object.assign(pageMapping[currentFile.pageId], { configs })
  },

  [types.ADD_PAGE_LOADED](state, pageContent) {
    Vue.set(state.pageMapping, pageContent.id, pageContent)
  },

  [types.UPDATE_PAGE](state, { pageId, data }) {
    if (!pageId) return
    Object.keys(data).forEach(key => {
      set(state.pageMapping[pageId], key, data[key])
    })
  },

  [types.FALLBACK_PAGE](state, { pageId, fallbackData }) {
    const { componentTree, configs, functions } = fallbackData
    const targetPage = state.pageMapping[pageId]
    targetPage.componentTree.splice(0, 999999, ...componentTree)
    targetPage.functions.splice(0, 999999, ...functions)
    Object.assign(targetPage.configs, configs)
  },

  [types.ADD_FILES](state, { pageId, type, content }) {
    const { pageMapping } = state
    const targetPage = pageMapping[pageId]
    targetPage[type].push(content)
  },

  [types.DEL_FILES](state, { pageId, fid }) {
    const { currentFile, pageMapping } = state
    const targetPageId = pageId || currentFile.pageId
    const targetPage = pageMapping[targetPageId]

    const targetIndex = targetPage.functions.findIndex(e => e.fid === fid)
    targetPage.functions.splice(targetIndex, 1)
  },

  [types.SET_COMPONENT_REMOVING_STATE](state, val) {
    state.readyToRemoveComponent = val
  },

  [types.SET_PROJECT_ORIGIN](state, projectOrigin) {
    state.projectOrigin = projectOrigin
  },

  [types.SET_PROJECT_ID](state, id) {
    state.projectId = id
  },

  [types.SET_IS_PRODUCTION](state, isProduction) {
    state.isProduction = isProduction
  },

  [types.SET_PROJECT_NAME](state, name) {
    state.projectName = name
  },

  [types.SET_PROJECT_PERMISSION_TYPE](state, permissionType) {
    state.permissionType = permissionType
  },

  [types.SET_PROJECT_CONSTANTS_TEMPLATE](state, data) {
    state.projectConstants = data
  },

  [types.SET_PROJECT_DATA_SOURCES](state, dataSources) {
    state.projectDataSources = dataSources
  },

  [types.SET_PROJECT_PARAMS](state, params) {
    state.projectParams = params
  },

  [types.SET_PROJECT_DICTIONARY](state, dictionary) {
    state.projectDictionary = dictionary
  },

  [types.SET_EDITOR_STATE](state, val) {
    state.editorLoading = val
  },

  [types.SET_STATE](_, { project, path, value }) {
    Vue.set(project, path, value)
  },

  [types.SET_DRAGGING_STATE](state, payload) {
    const { component, position } = payload
    state.draggingComponent = component
    state.draggingPosition = position
  },

  [types.SET_CURRENT_COMPONENTS](state, components) {
    state.currentComponents = components
  },

  [types.SET_CURRENT_CODE](state, code) {
    state.currentFile.data.content = code
  },

  [types.FILE_SAVING_STATE](state, val) {
    state.fileSaveLoading = val
  },

  [types.SELECT_FILE](state, file) {
    state.currentFile = file || {}
    if (file.type === 'ui') {
      state.componentTreeHistory = [cloneDeep(file.data.componentTree)]
      state.currentHistoryIndex = 0
    }
  },

  [types.SET_PREVIEW_FILE](state, file) {
    const { previewingFile, openedFiles } = state

    if (!file) {
      state.previewingFile = file
      return
    }

    const lastPreviewingFileiIndex = openedFiles.findIndex(
      item => item === previewingFile
    )

    const openedFile = openedFiles.find(item => item.hash === file.hash)

    if (openedFile) {
      state.currentFile = openedFile
      return
    }

    state.previewingFile = file
    if (lastPreviewingFileiIndex > -1) {
      openedFiles.splice(lastPreviewingFileiIndex, 1, file)
    } else {
      openedFiles.push(file)
    }
    state.currentFile = file

    if (file.type === 'ui') {
      state.componentTreeHistory = [cloneDeep(file.data.componentTree)]
      state.currentHistoryIndex = 0
      state.lastSavedComponentTree[file.hash] = cloneDeep(
        file.data.componentTree
      )
    }
  },

  [types.CLOSE_FILE](state, file) {
    const {
      openedFiles,
      currentFile: { hash },
      lastSavedComponentTree
    } = state
    const index = openedFiles.indexOf(file)

    if (state.currentFile === file) {
      state.currentFile = openedFiles[index + 1] || openedFiles[index - 1] || {}
    }
    state.openedFiles.splice(index, 1)
    lastSavedComponentTree[hash] = null
  },

  [types.REMOVE_COMPONENT](state, payload) {
    const { uuid, componentMapping } = payload
    const { path } = componentMapping[uuid]
    const { currentFile, currentDialog } = state
    if (!currentFile) return
    const tree = currentDialog
      ? currentDialog._children
      : currentFile.data.componentTree
    const pathCopy = clone(path)
    pathCopy.pop()
    let pointerOfUpper = tree
    // 根据 path 从上往下层级找组件
    pathCopy.forEach(currentUUID => {
      const onlyOneSlot = isOnlyOneSlot(pointerOfUpper)
      if (onlyOneSlot) {
        pointerOfUpper = pointerOfUpper.find(e => e.uuid === currentUUID)
          ._children
      } else {
        let temp = null
        Object.keys(pointerOfUpper).forEach(slotName => {
          const target = pointerOfUpper[slotName].find(
            e => e.uuid === currentUUID
          )
          if (target) {
            temp = target._children
          }
        })
        pointerOfUpper = temp
      }
    })

    const onlyOneSlot = isOnlyOneSlot(pointerOfUpper)

    if (onlyOneSlot) {
      pointerOfUpper.splice(
        pointerOfUpper.findIndex(e => e.uuid === uuid),
        1
      )
    } else {
      Object.keys(pointerOfUpper).forEach(slotName => {
        const slotChildren = pointerOfUpper[slotName]
        const indexOfComponentToRemove = slotChildren.findIndex(
          e => e.uuid === uuid
        )
        if (indexOfComponentToRemove !== -1) {
          slotChildren.splice(indexOfComponentToRemove, 1)
        }
      })
    }
  },

  [types.MODIFY_COMPONENT_PROPS](state, configs) {
    state.currentComponents[0].props = configs
  },

  [types.MODIFY_COMPONENT_CHILHREN](state, _children) {
    state.currentComponents[0]._children = _children
  },

  [types.SET_PLACEHOLDER](state, payload) {
    state.placeholder = payload
  },

  [types.REMOVE_PLACEHOLDER](state, payload) {
    state.placeholder = null
  },

  // 放置组件
  [types.REPLACE_PLACEHOLDER](state, payload) {
    const { componentOrPresetData, componentMapping, isPreset } = payload
    const { placeholder, currentFile } = state

    if (!placeholder) return
    const isNewComponent = !componentOrPresetData.uuid

    const {
      targetUUID,
      slotName,
      slotParentUUID,
      position,
      tableSlotIndex
    } = placeholder

    if (isPreset && slotParentUUID) {
      Notification.warning({
        title: '该插槽不支持放置预设',
        duration: 1200,
        position: 'top-left'
      })
      state.placeholder = null
      return
    }

    if (tableSlotIndex !== 'none' && !componentOrPresetData.beTableSlot) {
      Notification.warning({
        title: '该组件暂不支持置入 Table',
        duration: 700,
        position: 'top-left'
      })
      state.placeholder = null
      return
    }

    const { data: fileData } = currentFile
    const componentTree = state.currentDialog
      ? state.currentDialog._children
      : fileData.componentTree

    let parentNode
    let targetList
    let placeholderIndex = 0

    if (targetUUID) {
      parentNode =
        componentMapping[componentMapping[targetUUID].parentUUID] || {}
      targetList = parentNode ? parentNode._children : componentTree
      if (slotName === 'root') {
        targetList = componentTree
      } else {
        targetList =
          slotName === 'default'
            ? parentNode._children
            : parentNode._children[slotName]
      }
      let target = targetList.filter(item => item.uuid === targetUUID)[0]

      placeholderIndex =
        position.includes('bottom') || position.includes('right')
          ? targetList.indexOf(target) + 1
          : targetList.indexOf(target)
    } else {
      if (slotName === 'root') {
        targetList = componentTree
      } else {
        parentNode = componentMapping[slotParentUUID] || {}
        let children = parentNode._children
        targetList = slotName === 'default' ? children : children[slotName]
      }
    }

    // table slot
    if (tableSlotIndex !== 'none') {
      componentOrPresetData.tableSlotIndex = tableSlotIndex
      if (componentOrPresetData.componentName === 'XCol') {
        componentOrPresetData.props.minHeight = 40
      }
    }

    state.placeholder = null

    let newComponent

    if (isPreset) {
      const { content } = componentOrPresetData
      let presetContent = JSON.stringify(content)

      let uuidNumber = 0

      const matchedArr = presetContent.match(/{{uuid\d+}}/g)
      let matchedNumber
      if (matchedArr) {
        matchedNumber = matchedArr.map(value => value.match(/\d+/)[0])
      }

      if (matchedNumber) {
        uuidNumber = Math.max.apply(
          null,
          matchedNumber.map(v => Number(v))
        )
      }

      Array.from({
        length: uuidNumber
      }).forEach((_, index) => {
        const reg = new RegExp(`{{uuid${index + 1}}}`, 'g')
        const newUUID = uuidv1()
        presetContent = presetContent.replace(reg, newUUID)
      })

      newComponent = JSON.parse(presetContent)

      newComponent.forEach(component => {
        targetList.splice(placeholderIndex, 0, component)
        placeholderIndex += 1
      })
    } else {
      const uuid = uuidv1()

      let parentPath = []
      let thisPath = [isNewComponent ? uuid : componentOrPresetData.uuid]
      const parentUUID = (parentNode || {}).uuid

      // change parentPath here
      if (parentUUID) {
        generatePath(parentUUID, componentMapping, parentPath)
        thisPath = parentPath.concat(thisPath)
      }

      if (isNewComponent) {
        newComponent = {
          ...componentOrPresetData,
          uuid,
          parentUUID,
          path: thisPath
        }
      } else {
        newComponent = Object.assign(componentOrPresetData, {
          parentUUID,
          path: thisPath
        })
      }

      const { _children } = componentOrPresetData
      if (_children && !isNewComponent) {
        reCalcPath(_children, thisPath)
      }

      if (_children && isNewComponent) {
        const onlyOneSlot = isOnlyOneSlot(_children)

        if (onlyOneSlot) {
          newComponent._children = generateChildComponentsData(
            _children,
            uuid,
            thisPath
          )
        } else {
          Object.keys(_children).forEach(name => {
            newComponent._children[name] = generateChildComponentsData(
              _children[name],
              uuid,
              thisPath
            )
          })
        }
      }

      targetList.splice(placeholderIndex, 0, newComponent)
    }
  },

  [types.SET_FILESAVED_STATE](state, { pageId, value }) {
    const { openedFiles, currentFile, previewingFile } = state
    if (pageId === currentFile.pageId) {
      currentFile.unsaved = value
      if (previewingFile === currentFile) {
        state.previewingFile = null // 将预打开文件变为已打开文件
      }
    } else {
      openedFiles.find(f => f.pageId === pageId).unsaved = value
    }
  },

  [types.HISTORY_SAVE](state, newRecord) {
    let { componentTreeHistory, currentHistoryIndex } = state

    if (currentHistoryIndex + 1 === componentTreeHistory.length) {
      componentTreeHistory.push(cloneDeep(newRecord))
    } else {
      componentTreeHistory = componentTreeHistory.slice(
        0,
        currentHistoryIndex || 1
      )
      componentTreeHistory.push(cloneDeep(newRecord))
    }

    state.currentHistoryIndex = componentTreeHistory.length - 1
  },

  [types.UNDO_OR_REDO](state, offset) {
    // offset: { undo: -1, redo: 1 }
    const { currentFile, pageMapping } = state
    state.currentHistoryIndex += offset
    const nextStateIndex = state.currentHistoryIndex

    const targetHistory = cloneDeep(state.componentTreeHistory[nextStateIndex])

    pageMapping[currentFile.pageId].componentTree = targetHistory
    currentFile.data.componentTree = targetHistory

    state.currentFile.unsaved = true
  },

  [types.JUMP_TO_LAST_SAVED_VERSION](state) {
    const {
      currentFile: { hash },
      lastSavedComponentTree
    } = state
    state.currentFile.data.componentTree = cloneDeep(
      lastSavedComponentTree[hash]
    )
    state.currentFile.unsaved = false
  },

  [types.UPDATE_FUNCTION_PARAMS](state, params) {
    const { data } = state.currentFile
    data.params = params
    data.content = data.content.replace(
      /(function\smain\(\{).*(\}\)\s\{)/,
      '$1' + params.join(', ') + '$2'
    )
  },

  [types.RESET_EDITOR](state) {
    Object.assign(state, {
      currentComponents: [],
      readyToRemoveComponent: false,

      pageMapping: {},
      currentFile: {},
      fileSaveLoading: false,
      componentTreeHistory: [],
      lastSavedComponentTree: {},
      currentHistoryIndex: 0,

      previewingFile: null,
      openedFiles: [],
      draggingItem: null,
      placeholder: null,
      dragItemHelper: null,
      draggingComponent: null,
      draggingPosition: {},
      editorLoading: true
    })
  },

  [types.ADD_DIALOG](state, { dialog, page }) {
    if (!page.dialogs) {
      Vue.set(page, 'dialogs', [])
    }
    page.dialogs.push(dialog)
  },

  [types.SET_CURRENT_DIALOG](state, dialog) {
    state.currentDialog = dialog
    state.currentComponents = []
    state.currentModiferTabName = 'dialog'
  },

  [types.UPDATE_DIALOG](state, payload) {
    set(state.currentDialog, payload.path, payload.value)
  },

  [types.DELETE_DIALOG](state, payload) {
    const { page, dialog } = payload
    const index = page.dialogs.indexOf(dialog)

    if (index > -1) {
      page.dialogs.splice(index, 1)
    }
  },

  [types.SET_CURRENT_TAB](state, name) {
    state.currentModiferTabName = name
  },

  [types.REMOVE_PROJECT_TREE_NODE](state, payload) {
    const { target } = payload
    const parent = payload.parent || state.projectTree
    const index = parent.indexOf(target)
    if (~index) {
      parent.splice(index, 1)
    }
  },

  [types.ADD_PROJECT_TREE_NODE](state, payload) {
    const { target } = payload
    const pageData = target.pageContent
    const parent = payload.parent || state.projectTree
    parent.push(target)
    // 如果是页面节点，推入映射表
    if (pageData) {
      state.pageMapping[target.pageId] = pageData
    }
  },

  [types.UPDATE_PROJECT_TREE_NODE](state, payload) {
    const { target, data } = payload
    Object.keys(data).forEach(key => {
      target[key] = data[key]
      if (key === 'name') {
        if (target.type === 'folder') {
          // 如果更新的是 folder 节点名字，更新所有子节点里的 path
          target.children.forEach(page => {
            let path = `/${data[key]}`
            page.pageContent.path = path
            page.path = path
          })
        } else if (target.type === 'page') {
          const { pageId } = target
          if (pageId in state.pageMapping) {
            state.pageMapping[pageId].name = data.name
          }
        }
      }
    })
  },

  [types.ADD_VISITED_VIEW](state, view) {
    if (state.visitedViews.some(v => v.path === view.path)) return
    state.visitedViews.push(
      Object.assign({}, view, {
        title: view.meta.title || ''
      })
    )
  },

  [types.ADD_CACHED_VIEW](state, view) {
    if (state.cachedViews.includes(view.name)) return
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name)
    }
  },

  [types.DEL_VISITED_VIEW](state, view) {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        view.history.length = 0
        state.visitedViews.splice(i, 1)
        break
      }
    }
  },

  [types.DEL_CACHED_VIEW](state, view) {
    for (const i of state.cachedViews) {
      if (i === view.name) {
        const index = state.cachedViews.indexOf(i)
        state.cachedViews.splice(index, 1)
        break
      }
    }
  },

  [types.DEL_OTHERS_VISITED_VIEWS](state, view) {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.meta.affix || v.path === view.path
    })
  },

  [types.DEL_OTHERS_CACHED_VIEWS](state, view) {
    for (const i of state.cachedViews) {
      if (i === view.name) {
        const index = state.cachedViews.indexOf(i)
        state.cachedViews = state.cachedViews.slice(index, index + 1)
        break
      }
    }
  },

  [types.DEL_ALL_VISITED_VIEWS](state) {
    // keep affix tags
    const affixTags = state.visitedViews.filter(tag => tag.meta.affix)
    state.visitedViews = affixTags
  },

  [types.DEL_ALL_CACHED_VIEWS](state) {
    state.cachedViews = []
  },

  [types.UPDATE_VISITED_VIEW](state, view) {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view)
        break
      }
    }
  },

  [types.SET_RENDER_ROUTE](state, route) {
    state.renderRoute = route
  },

  [types.SET_PERMISSION_ROUTES](state, routes) {
    state.permissionAddRoutes = routes
    state.permissionRoutes = constantRoutes.concat(routes)
  },

  [types.ADD_ERROR_LOG](state, log) {
    state.errorLogs.push(log)
  },

  [types.CLEAR_ERROR_LOG](state) {
    state.errorLogs.splice(0)
  },

  [types.TOGGLE_SIDEBAR](state) {
    state.sidebar.opened = !state.sidebar.opened
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },

  [types.CLOSE_SIDEBAR](state) {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
  }
}
