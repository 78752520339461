export const SET_USER = 'SET_USER' // 设置登录用户信息
export const SET_LOGIN = 'SET_LOGIN' // 设置登录用户信息
export const SET_BRANDS = 'SET_BRANDS' // 设置品牌
export const SET_BRAND = 'SET_BRAND' // 选择品牌
export const SET_PROJECT_TREE = 'SET_PROJECT_TREE' // 设置当前项目目录树
export const SET_PROJECT_SETTING = 'SET_PROJECT_SETTING' // 设置当前项目配置
export const UPDATE_PAGE = 'UPDATE_PAGE' // 更新页面
export const FALLBACK_PAGE = 'FALLBACK_PAGE' // 回滚页面
export const ADD_PAGE_LOADED = 'ADD_PAGE_LOADED' // 添加加载好的页面进 pageMapping
export const ADD_FILES = 'ADD_FILES' // 改变页面文件，例如 func dialog
export const DEL_FILES = 'DEL_FILES' // 删除页面文件，例如 func dialog
export const SET_PROJECT_ORIGIN = 'SET_PROJECT_ORIGIN' // 设置页面元数据
export const SET_PROJECT_ID = 'SET_PROJECT_ID' // 设置当前工作项目ID
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME' // 设置当前工作项目名
export const SET_PROJECT_PERMISSION_TYPE = 'SET_PROJECT_PERMISSION_TYPE' // 设置当前工作项目权限类型
export const CLOSE_FILE = 'CLOSE_FILE' // 关闭文件
export const SELECT_FILE = 'SELECT_FILE' // 选中当前文件
export const SET_PREVIEW_FILE = 'SET_PREVIEW_FILE' // 设置当前预览文件
export const ADD_COMPONENT = 'ADD_COMPONENT' // 添加组件
export const REMOVE_COMPONENT = 'REMOVE_COMPONENT' // 删除组件
export const SET_PLACEHOLDER = 'SET_PLACEHOLDER' // 设置占位元素
export const REMOVE_PLACEHOLDER = 'REMOVE_PLACEHOLDER' // 移除占位元素
export const REPLACE_PLACEHOLDER = 'REPLACE_PLACEHOLDER' // 替换占位元素
export const UPDATE_BINDING_VALUE = 'UPDATE_BINDING_VALUE' // 组件更新 pageModel 中的值
export const SET_DRAGGING_STATE = 'SET_DRAGGING_STATE' // 拖拽状态
export const SET_CURRENT_COMPONENTS = 'SET_CURRENT_COMPONENTS' // 设置当前组件
export const MODIFY_COMPONENT_PROPS = 'MODIFY_COMPONENT_PROPS' // 组件属性修改
export const MODIFY_COMPONENT_CHILHREN = 'MODIFY_COMPONENT_CHILHREN' // 组件子元素修改
export const SYNC_COMPINENT_MAPPING = 'SYNC_COMPINENT_MAPPING' // 组件子元素修改
export const SET_CURRENT_CODE = 'SET_CURRENT_CODE' // 修改代码
export const SET_EDITOR_STATE = 'SET_EDITOR_STATE' // 代码编辑器加载状态
export const UNDO_OR_REDO = 'UNDO_OR_REDO' // 撤销或重做
export const HISTORY_SAVE = 'HISTORY_SAVE' // 状态记录
export const JUMP_TO_LAST_SAVED_VERSION = 'JUMP_TO_LAST_SAVED_VERSION' // 回到上次保存状态
export const SET_FILESAVED_STATE = 'SET_FILESAVED_STATE' // 设置文件未保存状态
export const UPDATE_FUNCTION_PARAMS = 'UPDATE_FUNCTION_PARAMS' // 修改方法注入参数
export const FILE_SAVING_STATE = 'FIlE_SAVING_STATE' // 修改保存状态
export const SET_STATE = 'SET_STATE' // 设置数据源
export const SET_IS_PRODUCTION = 'SET_IS_PRODUCTION' // 设置产品环境
export const SET_COMPONENT_REMOVING_STATE = 'SET_COMPONENT_REMOVING_STATE' // 是否待删除
export const MODIFY_PAGE_CONFIGS = 'MODIFY_PAGE_CONFIGS' // 修改页面配置
export const RESET_EDITOR = 'RESET_EDITOR' // 重置编辑器
export const ADD_DIALOG = 'ADD_DIALOG' // 添加弹层
export const SET_CURRENT_DIALOG = 'SET_CURRENT_DIALOG' // 设置弹层
export const UPDATE_DIALOG = 'UPDATE_DIALOG' // 更新弹层
export const DELETE_DIALOG = 'DELETE_DIALOG' // 删除弹层
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB' // 更新当前激活的编辑区的 tab
export const SET_PROJECT_CONSTANTS_TEMPLATE = 'SET_PROJECT_CONSTANTS_TEMPLATE' // 设置项目全局常量
export const SET_PROJECT_DATA_SOURCES = 'SET_PROJECT_DATA_SOURCES' // 设置项目数据源
export const SET_PROJECT_PARAMS = 'SET_PROJECT_PARAMS' // 设置项目参数
export const SET_PROJECT_DICTIONARY = 'SET_PROJECT_DICTIONARY' // 设置项目字典数据
export const REMOVE_PROJECT_TREE_NODE = 'REMOVE_PROJECT_TREE_NODE' // 移除项目目录树节点数据
export const ADD_PROJECT_TREE_NODE = 'ADD_PROJECT_TREE_NODE' // 增加项目目录树节点数据
export const UPDATE_PROJECT_TREE_NODE = 'UPDATE_PROJECT_TREE_NODE' // 更新项目目录树节点数据
export const SET_PROJECT_URL_MAP_TEMPLATE = 'SET_PROJECT_URL_MAP_TEMPLATE'
export const ADD_VISITED_VIEW = 'ADD_VISITED_VIEW'
export const ADD_CACHED_VIEW = 'ADD_CACHED_VIEW'
export const DEL_VISITED_VIEW = 'DEL_VISITED_VIEW'
export const DEL_CACHED_VIEW = 'DEL_CACHED_VIEW'
export const DEL_OTHERS_VISITED_VIEWS = 'DEL_OTHERS_VISITED_VIEWS'
export const DEL_OTHERS_CACHED_VIEWS = 'DEL_OTHERS_CACHED_VIEWS'
export const DEL_ALL_VISITED_VIEWS = 'DEL_ALL_VISITED_VIEWS'
export const DEL_ALL_CACHED_VIEWS = 'DEL_ALL_CACHED_VIEWS'
export const UPDATE_VISITED_VIEW = 'UPDATE_VISITED_VIEW'
export const SET_RENDER_ROUTE = 'SET_RENDER_ROUTE'
export const SET_PERMISSION_ROUTES = 'SET_PERMISSION_ROUTES'
export const ADD_ERROR_LOG = 'ADD_ERROR_LOG'
export const CLEAR_ERROR_LOG = 'CLEAR_ERROR_LOG'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'
