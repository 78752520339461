import { render, staticRenderFns } from "./fallback-component.vue?vue&type=template&id=768b23d1&scoped=true&"
import script from "./fallback-component.vue?vue&type=script&lang=js&"
export * from "./fallback-component.vue?vue&type=script&lang=js&"
import style0 from "./fallback-component.vue?vue&type=style&index=0&id=768b23d1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768b23d1",
  null
  
)

export default component.exports