import { cloneDeep, set } from 'lodash-es'
import API from '@/api'
import Axios from '@/api/axios'
import { Notification, Message } from 'element-ui'
import Project from '@/pages/editor/modules/catalog/project'
import * as types from './mutation-types'
import Dialog from './models/dialog'
import { asyncRoutes } from '@/router'
import menuToRoutes from '@/utils/menuToRoutes'
import { setToken, removeToken } from '@/utils/auth'
import parseTable from '@/utils/parseTable'
import bus from '@/utils/bus'

const successNotify = msg => {
  Notification.success({
    title: msg,
    duration: 1500,
    position: 'top-left'
  })
}

// 登录
export const login = async ({ commit, state }, userInfo) => {
  const { username, password, code, fjh } = userInfo
  return API.login({ user_no: username.trim(), password, captcha: code, fjh }).then(res => {
    commit('SET_LOGIN', userInfo)
    // 多品牌模式
    if (res.users) {
      commit('SET_BRANDS', res.users)
    } else {
      commit('SET_BRANDS', [])
    }
    // 清楚遗留品牌
    window.localStorage.removeItem('brands')
    setToken('X')
  })
}

// 登出
export const logout = async ({ commit }) => {
  return API.logout().then(() => {
    commit(types.SET_USER, {})
    removeToken()
  })
}

// 单点登录
export const loginByCode = async({ commit }, code) => {
  return API.loginByCode({ code: code }).then(res => {
    // 多品牌模式
    if (res.users) {
      commit('SET_BRANDS', res.users)
    } else {
      commit('SET_BRANDS', [])
    }
    // 清楚遗留品牌
    window.localStorage.removeItem('brands')
    setToken('X')
  })
}

// 选择品牌
export const chooseBrand = async ({ commit }, userId) => {
  return API.chooseBrand(userId).catch().then(() => {
    commit(types.SET_BRAND, userId)
  })
}

// 获取用户信息
export const getUserInfo = async ({ commit, state }) => {
  if (state.user.userid) return Promise.resolve()
  const userData = await API.getUserInfo()
  const { userinfo = {} } = userData
  if (userData.needpwd) {
    userinfo.needpsd = true
  }
  commit(types.SET_USER, userinfo)
}

// 异步初始化
export const asyncInitProject = async ({ commit, dispatch }) => {
  dispatch('initDictionary')
}

export const initDictionary = async ({ commit }) => {
  const { data: dictionaryOrigin } = await API.getDictionary()
  const dictionary = {}
  dictionaryOrigin.forEach(dic => {
    const value = parseTable(JSON.parse(dic.dicvalue))
    dictionary[dic.diccode] = {
      ...dic,
      data: value.data
    }
  })
  commit(types.SET_PROJECT_DICTIONARY, dictionary)
}

// 编辑器初始化
export const initEditor = async ({ commit, dispatch }, projectId) => {
  const project = await API.getProject(projectId)

  const {
    projectName,
    permissionType,
    tree: projectTree,
    dataSources: projectDataSources,
    params: projectParams,
    setting: projectSetting,
    constants: projectConstants,
    urlMap: projectUrlMap
  } = project

  // 将所有页面存入 pageMapping 中
  projectTree.forEach(node => {
    if (node.children) {
      node.children.forEach(pageNode => {
        if (pageNode.type === 'page') {
          dispatch('addPageLoaded', pageNode.pageContent)
        }
      })
    }
  })

  commit(types.SET_PROJECT_ORIGIN, project)
  commit(types.SET_PROJECT_ID, projectId)
  commit(types.SET_PROJECT_NAME, projectName)
  commit(types.SET_PROJECT_PERMISSION_TYPE, permissionType)
  commit(types.SET_PROJECT_TREE, projectTree)
  commit(types.SET_PROJECT_DATA_SOURCES, projectDataSources)
  commit(types.SET_PROJECT_PARAMS, projectParams)
  commit(types.SET_PROJECT_CONSTANTS_TEMPLATE, projectConstants)
  commit(types.SET_PROJECT_URL_MAP_TEMPLATE, projectUrlMap)

  dispatch('updateProjectSetting', projectSetting)

  return project
}

export const updateProjectSetting = ({ getters, commit }, payload) => {
  let newProject = cloneDeep(getters.projectSetting) || {}

  if (payload._updatePath) {
    // 如果带路径则只更新单个属性
    set(newProject, payload._updatePath, payload.value)
  } else {
    // 否则更新全部
    Object.keys(payload).forEach(key => {
      set(newProject, key, payload[key])
    })
  }

  commit(types.SET_PROJECT_SETTING, newProject)
}

// 将加载完的 page 推入 mapping
export const addPageLoaded = ({ commit }, pageContent) => {
  commit(types.ADD_PAGE_LOADED, pageContent)
}

export const saveProject = async ({ state }, { showNotify = true } = {}) => {
  const {
    projectId,
    projectName,
    permissionType,
    projectTree,
    projectSetting,
    projectConstants,
    projectUrlMap
  } = state
  const data = new Project(projectId)
  Object.assign(data, {
    projectName,
    permissionType,
    tree: projectTree,
    setting: projectSetting,
    constants: projectConstants,
    urlMap: projectUrlMap
  })

  await API.updateFullProject(data)
  if (showNotify) {
    successNotify('保存配置成功！')
  }
}

/**
 * 发布项目
 * @param {*} param0
 * @param {*} payload
 */
export const releaseProject = async ({ state, dispatch }, payload) => {
  const { projectId }  = state
  const { comment } = payload
  await API.releaseProject(projectId, comment)
  successNotify('页面发布成功！')
}

/**
 * 发布多个页面
 * @param {*} param0
 * @param {*} payload
 */
export const batchReleasePage = async (
  { state, commit, dispatch },
  payload
) => {
  const { pages, comment, type } = payload
  const { projectName, projectUrlMap } = state
  pages.forEach(page => {
    projectUrlMap[page.path] = page.hash
  })
  const urlMapPath = '/.urlmap'
  let headCommit = ''
  if (!headCommit) {
    // headCommit = await API.getHeadCommit(projectName, urlMapPath)
  }
  commit(types.SET_PROJECT_URL_MAP_TEMPLATE, projectUrlMap)
  const { urlMap } = await API.getProject(projectName)
  const releaseResult = await dispatch('releasePageDataScript', { urlMap })
  const releaseInfo = {
    type: 'pages',
    projectName,
    url: releaseResult.url,
    urlMapHash: headCommit.hash,
    urlMapHashAt: headCommit.committed_at,
    comment,
    pages,
    version: 3
  }
  const logResult = await API.putScriptReleaseLog(releaseInfo)
  let text = type === 'fallback' ? '页面回滚成功' : '页面发布成功'
  successNotify(text)
  return logResult
}

export const releasePageDataScript = async ({ state }, payload) => {
  const { projectName } = state
  const { urlMap } = payload
  const pagePaths = Object.keys(urlMap)
  const allPageData = {}
  return Promise.all(
    pagePaths.map(async path => {
      const { pages } = await API.getProject(projectName)
      const data = pages[path]
      allPageData[path] = data
    })
  ).then(async () => {
    const scriptContent = `window.DorisPageData = ${JSON.stringify(
      allPageData
    )}`
    const releaseResult = await API.releasePageDataScript(
      projectName,
      scriptContent
    )
    return releaseResult
  })
}

// 添加文件
export const addFiles = ({ state, commit, getters }, payload) => {
  const { type, content } = payload
  const pageId = payload.pageId || state.currentFile.pageId
  commit(types.ADD_FILES, { pageId, type, content })
}

// 删除文件
export const delFiles = async (
  { state, commit, getters, dispatch },
  { pageId, fid }
) => {
  if (!fid) return
  commit(types.DEL_FILES, { pageId, fid })
  await dispatch('saveProject')
}

// 选中组件编辑
export const setCurrentComponent = ({ state, commit, dispatch }, component) => {
  commit(types.SET_CURRENT_COMPONENTS, component ? [component] : [])
  dispatch('changeCurrentTab')

  // 暂时用全局变量标识
  if (window.configChanged) {
    dispatch('historySave')
  }

  window.configChanged = false
}

// 选中多个组件
export const setMultiComponents = ({ state, commit, dispatch }, components) => {
  commit(types.SET_CURRENT_COMPONENTS, components)
  dispatch('changeCurrentTab')

  // 暂时用全局变量标识
  if (window.configChanged) {
    dispatch('historySave')
  }

  window.configChanged = false
}

// 删除组件
export const removeComponent = (
  { state, commit, dispatch, getters },
  uuids
) => {
  const { componentMapping } = getters
  uuids.forEach(uuid => commit(types.REMOVE_COMPONENT, { uuid, componentMapping }))
  dispatch('setFileUnsaved')
  commit(types.SET_CURRENT_COMPONENTS, [])
  dispatch('historySave')
}

// 放置组件
export const replacePlaceholder = (
  { getters, state, commit, dispatch },
  { componentOrPresetData, isPreset }
) => {
  const { componentMapping } = getters
  commit(types.REPLACE_PLACEHOLDER, {
    componentOrPresetData,
    componentMapping,
    isPreset
  })
  dispatch('setFileUnsaved')
  dispatch('historySave')
}

// 选择文件
export const selectFile = ({ state, commit }, file) => {
  commit(types.SELECT_FILE, file)
  commit(types.SET_CURRENT_COMPONENTS, [])
}

// 关闭文件
export const closeFile = ({ state, commit }, file) => {
  [].concat(file).forEach(item => {
    commit(types.CLOSE_FILE, item)
  })
  commit(types.SET_CURRENT_COMPONENTS, [])
}

// 打开方法或者 UI 预览
export const setPreviewFile = ({ state, commit, dispatch }, file) => {
  const { pageMapping } = state
  const currentPage = pageMapping[file.pageId]

  let content

  if (file.type === 'ui') {
    content = { componentTree: currentPage.componentTree }
  } else if (file.type === 'func') {
    content = currentPage.functions.find(f => f.fid === file.hash)
  } else if (file.type === 'const') {
    content = currentPage.constants
  }

  commit(types.SET_PREVIEW_FILE, {
    data: content,
    ...file
  })
  commit(types.SET_CURRENT_COMPONENTS, [])
  dispatch('changeCurrentTab')
}

export const historySave = ({ state, commit }) => {
  commit(types.HISTORY_SAVE, state.currentFile.data.componentTree)
}

export const undo = ({ commit, state, getters }) => {
  if (getters.undoDisabled) return
  commit(types.UNDO_OR_REDO, -1)
}

export const redo = ({ commit, state, getters }) => {
  if (getters.redoDisabled) return
  commit(types.UNDO_OR_REDO, 1)
}

export const backToLastSavedVersion = ({
  state,
  commit,
  dispatch,
  getters
}) => {
  if (getters.lastSavedDisabled) return
  commit(types.JUMP_TO_LAST_SAVED_VERSION)
  dispatch('historySave')
}

// 修改页面配置
export const modifyPageConfigs = ({ commit, state }, configs) => {
  commit(types.MODIFY_PAGE_CONFIGS, configs)
}

// 重置编辑器
export const resetEditor = ({ state, commit }) => {
  commit(types.RESET_EDITOR)
}

// 回滚页面
export const fallbackPage = ({ state, commit, getters, dispatch }) => {
  const { currentPage } = getters
  const { projectName } = state

  API.getProject(projectName)
    .then(project => {
      const { pages } = project
      const data = pages[currentPage.path]
      commit(types.FALLBACK_PAGE, {
        fallbackData: data,
        pageId: currentPage.id
      })
    })
    .then(_ => {
      dispatch('saveProject')
    })
}

// 设置页面未保存
export const setFileUnsaved = ({ state, commit }, pageId) => {
  const targetPageId = pageId || state.currentFile.pageId
  commit(types.SET_FILESAVED_STATE, {
    value: true,
    pageId: targetPageId
  })
}

// 添加弹层
export const addDialog = ({ state, commit, getters }) => {
  const { currentPage } = getters

  commit(types.ADD_DIALOG, {
    dialog: new Dialog(),
    page: currentPage
  })
}

// 删除弹层
export const deleteDialog = ({ state, commit, getters }, dialog) => {
  if (dialog === state.currentDialog) {
    commit(types.SET_CURRENT_DIALOG, null)
  }
  commit(types.DELETE_DIALOG, {
    dialog,
    page: getters.currentPage
  })
}

// 被动响应变更属性修改栏当前激活标签
export const changeCurrentTab = ({ state, commit }) => {
  const { currentComponents, currentDialog } = state
  let name
  const count = currentComponents.length
  if (count) {
    if (count === 1) {
      name = 'component'
    } else {
      // name = 'components'
      name = 'page'
    }
  } else if (currentDialog) {
    name = 'dialog'
  } else {
    name = 'page'
  }
  commit(types.SET_CURRENT_TAB, name)
}

// routers
export const generateRoutes = ({ commit }) => {
  return API.getMenu().then(menu => {
    const routes = menuToRoutes(menu)
    const accessedRoutes = asyncRoutes.concat(routes)
    commit(types.SET_PERMISSION_ROUTES, accessedRoutes)
    return accessedRoutes
  })
}

// tagView
export const addView = ({ dispatch }, view) => {
  dispatch('addVisitedView', view)
  dispatch('addCachedView', view)
}

export const addVisitedView = ({ commit }, view) => {
  commit('ADD_VISITED_VIEW', view)
}

export const addCachedView = ({ commit }, view) => {
  commit('ADD_CACHED_VIEW', view)
}

export const delView = ({ dispatch, state }, view) => {
  return new Promise(resolve => {
    dispatch('delVisitedView', view)
    dispatch('delCachedView', view)
    resolve({
      visitedViews: [...state.visitedViews],
      cachedViews: [...state.cachedViews]
    })
  })
}

export const delVisitedView = ({ commit, state }, view) => {
  return new Promise(resolve => {
    commit('DEL_VISITED_VIEW', view)
    resolve([...state.visitedViews])
  })
}

export const delCachedView = ({ commit, state }, view) => {
  return new Promise(resolve => {
    commit('DEL_CACHED_VIEW', view)
    resolve([...state.cachedViews])
  })
}

export const delOthersViews = ({ dispatch, state }, view) => {
  return new Promise(resolve => {
    dispatch('delOthersVisitedViews', view)
    dispatch('delOthersCachedViews', view)
    resolve({
      visitedViews: [...state.visitedViews],
      cachedViews: [...state.cachedViews]
    })
  })
}

export const delOthersVisitedViews = ({ commit, state }, view) => {
  return new Promise(resolve => {
    commit('DEL_OTHERS_VISITED_VIEWS', view)
    resolve([...state.visitedViews])
  })
}

export const delOthersCachedViews = ({ commit, state }, view) => {
  return new Promise(resolve => {
    commit('DEL_OTHERS_CACHED_VIEWS', view)
    resolve([...state.cachedViews])
  })
}

export const delAllViews = ({ dispatch, state }, view) => {
  return new Promise(resolve => {
    dispatch('delAllVisitedViews', view)
    dispatch('delAllCachedViews', view)
    resolve({
      visitedViews: [...state.visitedViews],
      cachedViews: [...state.cachedViews]
    })
  })
}

export const delAllVisitedViews = ({ commit, state }) => {
  return new Promise(resolve => {
    commit('DEL_ALL_VISITED_VIEWS')
    resolve([...state.visitedViews])
  })
}

export const delAllCachedViews = ({ commit, state }) => {
  return new Promise(resolve => {
    commit('DEL_ALL_CACHED_VIEWS')
    resolve([...state.cachedViews])
  })
}

export const updateVisitedView = ({ commit }, view) => {
  commit('UPDATE_VISITED_VIEW', view)
}

export const addErrorLog = ({ commit }, log) => {
  commit('ADD_ERROR_LOG', log)
}

export const clearErrorLog = ({ commit }) => {
  commit('CLEAR_ERROR_LOG')
}

// sidebar
export const toggleSideBar = ({ commit }) => {
  commit(types.TOGGLE_SIDEBAR)
}

export const closeSideBar = ({ commit }) => {
  commit(types.CLOSE_SIDEBAR)
}

// 打开内嵌页面
export const openPage = ({ commit }, { url, pageId, query, title }) => {
  bus.$emit('openView', { url, pageId, query, title })
}

// 内嵌页面返回（默认返回上一页）
export const backPage = ({ commit }, { callback = () => {}, index = 0 }) => {
  bus.$emit('backView', { callback, index })
}

// 打开弹框
export const openDialog = ({ commit }, { project, name, query }) => {
  commit('SET_STATE', { project, path: name, value: { visible: true, query } })
}

// 关闭弹框
export const closeDialog = ({ commit }, { project, name }) => {
  commit('SET_STATE', { project, path: name, value: { visible: false } })
}

// 清除表格选择
export const clearSelection = (_, { components, id } = {}) => {
  if (!components) {
    return Message.error('缺少 $components: getSelection 方法需要传入 $components 参数')
  }
  if (!id) {
    return Message.error('缺少 id: getSelection 方法需要传入组件 id 参数')
  }
  if (!components[id]) {
    return Message.error(`缺少 ${id} 实例: 确保给 table 组件设置了组件 ID`)
  }
  components[id].clearSelection()
}

// 页面显示消息
export const message = (_, { message, type = 'info', duration = 3000, center = false }) => {
  return Message({
    message,
    type,
    duration,
    center
  })
}

// 执行页面操作
export const execAction = (_, {
  pageId,
  actionId,
  reload = false,
  comfirmContent = '',
  params = {},
  success,
  fail,
  $project,
  $tableRef,
  $clickButtonPopover,
} = {}) => {
  if ($clickButtonPopover && !$clickButtonPopover({ comfirmContent })) {
    return false
  }

  return API.execAction({
    pageId: pageId || $project.projectId,
    actionId,
    params
  }).then(data => {
    if (typeof success === 'string' && success) {
      Message.success(success)
    } else if (typeof success === 'function') {
      success(data)
    }
    if (reload && $tableRef && $tableRef.$parent) {
      $tableRef.$parent.reloadTable()
    }
    return data
  }).catch(error => {
    if (typeof fail === 'string') {
      Message.error(fail || error)
    } else if (typeof fail === 'function') {
      fail()
    }
  })
}

// 执行页面删除逻辑
export const deleteAction = ({ dispatch }, payload) => {
  return dispatch('execAction', {
    ...payload,
    reload: true,
    comfirmContent: '确认删除该数据吗？',
    success: '删除成功',
    fail: '删除失败'
  })
}

// 执行 table 页面操作
export const execTableAction = (
  { dispatch },
  {$project, $components, actionId, id, key, success, fail}
) => {
  if (!actionId) {
    return Message.error('缺少 actionId: execTableAction 方法需要传入 actionId 参数')
  }
  if (!id) {
    return Message.error('缺少 id: execTableAction 方法需要传入组件 id 参数')
  }
  if (!$components[id]) {
    return Message.error(`缺少 ${id} 实例: 确保给 table 组件设置了组件 ID`)
  }
  const keyName = key || $project.dataSources[0].KEYS
  const keyRows = $components[id].selection.map(v => v[keyName])

  return dispatch('execAction', {
    pageId: $project.projectId,
    actionId,
    params: { ids: keyRows },
    success,
    fail
  })
}

// 发送请求
export const axios = (_, options) => {
  return Axios(options)
}
