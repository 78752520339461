<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="logo-container">
        <img src="@/assets/logoWithText.png" alt="logo" width="193" />
      </div>
      <div class="division" />
      <div class="content-container">
        <template v-if="currentStep === step.login">
          <div class="title-container">
            <h2 class="title">SCRM平台</h2>
            <h3 class="sub-title">全渠道会员管理</h3>
          </div>
          <el-form-item prop="username">
            <el-input
              width="50"
              ref="username"
              v-model="loginForm.username"
              prefix-icon="el-icon-user"
              placeholder="用户名"
              name="username"
              tabindex="1"
              autocomplete="on"
            />
          </el-form-item>

          <el-tooltip v-model="capsTooltip" content="大写键盘已锁定" placement="right" manual>
            <el-form-item prop="password">
              <el-input
                :key="passwordType"
                ref="password"
                v-model="loginForm.password"
                prefix-icon="el-icon-key"
                :type="passwordType"
                placeholder="密码"
                name="password"
                tabindex="2"
                autocomplete="on"
                @keyup.native="checkCapslock"
                @blur="capsTooltip = false"
                @keyup.enter.native="handleLogin"
              />
              <span class="show-pwd" @click="showPwd">
                <icon :name="passwordType === 'password' ? 'eye' : 'eye-open'" class="pwd-eye" />
              </span>
            </el-form-item>
          </el-tooltip>
          
          <!-- <el-form-item prop="fjh">
            <el-input
              width="50"
              ref="fjh"
              v-model="loginForm.fjh"
              prefix-icon="el-icon-user"
              placeholder="分机号"
              name="fjh"
              tabindex="3"
              autocomplete="on"
            />
          </el-form-item> -->
          <!-- <el-form-item prop="code">
            <div class="code-container">
              <el-input
                ref="code"
                v-model="loginForm.code"
                placeholder="验证码"
                name="code"
                maxlength="4"
                tabindex="4"
              />
              <el-tooltip content="点击刷新" placement="top">
                <div class="img-container" @click="refreshCode">
                  <img :src="codeUrl" />
                </div>
              </el-tooltip>
            </div>
          </el-form-item> -->

          <el-button :loading="loading" type="primary" @click.native.prevent="handleLogin">登录</el-button>
          <div v-if="false" class="thirdparty">
            <el-button type="text" @click="showDialog = true">社交帐号登录</el-button>
          </div>
        </template>
        <transition name="slide">
          <el-form-item prop="brand" v-if="currentStep === step.brand">
           <div class="brand-container">
              <label>品牌：</label>
              <el-select
                style="width: 190px;"
                placeholder="选择品牌"
                :value="choosedBrand"
                @change="handleChangeBrand"
              >
                <el-option
                  v-for="brand in brands"
                  :key="brand.USERID"
                  :label="brand.TEN_NAME"
                  :value="brand.USERID"
                >
                </el-option>
              </el-select>
           </div>
           <div class="comfirm-button">
              <el-button type="primary" @click="handleComfirmBrand">确定</el-button>
            </div>
          </el-form-item>
        </transition>
      </div>
    </el-form>

    <!-- 多端认证登录 -->
    <el-dialog title="社交账号登录" :visible.sync="showDialog">
      <social-sign />
    </el-dialog>

    <!-- 商标 -->
    <div class="footer-container">
      上海智简信息科技有限公司版权所有 沪ICP备13047937号-3 Copyright 2013 All
      Rights Reserved
    </div>

    <!-- 背景 canvas -->
    <BackgroundCanvas class="background-canvas" />
  </div>
</template>

<script>
import { validUsername } from '@/utils/validate'
import { API_HOST } from '@/api/constants'
import { mapState } from 'vuex'
import SocialSign from './social-signin'
import BackgroundCanvas from './background-canvas'

export default {
  name: 'Login',

  components: {
    SocialSign,
    BackgroundCanvas
  },

  data() {
    const step = {
      login: 'login',
      brand: 'brand'
    }
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error('请输入正确的用户名'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      // if (value.length < 6) {
      //   callback(new Error('密码至少 6 位数'))
      // } else {
      //   callback()
      // }
      callback()
    }
    const validateCode = (rule, value, callback) => {
      if (value.length !== 4) {
        callback(new Error('请输入正确的验证码'))
      } else {
        callback()
      }
    }
    return {
      currentStep: step.login,
      step,
      loginForm: {
        username: '',
        password: '',
        code: '',
        fjh: ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', validator: validateUsername }
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ],
        // code: [{ required: true, trigger: 'blur', validator: validateCode }]
      },
      passwordType: 'password',
      codeUrl: '',
      baseCodeUrl: `${API_HOST}/saas/action/user/captcha?width=130&height=48&len=4`,
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    }
  },

  computed: {
    ...mapState([
      'brands'
    ]),
    choosedBrand() {
      const matched = this.brands.filter(brand => brand.isChoosed)
      if (!matched.length) {
        return ''
      }
      return matched[0].USERID
    }
  },

  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },

  async mounted() {
    const { code } = this.$route.query
    if (code) {
      await this.$store.dispatch('loginByCode', code)
      this.shouldShowBrand()
    }
    this.refreshCode()
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },

  methods: {
    goToHome() {
      this.$router.push({
        path: this.redirect || '/',
        query: this.otherQuery
      })
    },
    refreshCode() {
      this.codeUrl = `${this.baseCodeUrl}&_${Math.random()}`
    },
    checkCapslock({ shiftKey, key } = {}) {
      if (key && key.length === 1) {
        if (
          (shiftKey && key >= 'a' && key <= 'z') ||
          (!shiftKey && key >= 'A' && key <= 'Z')
        ) {
          this.capsTooltip = true
        } else {
          this.capsTooltip = false
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false
      }
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    shouldShowBrand() {
      if (this.brands.length > 1) {
        this.currentStep = this.step.brand
        const defaultBrand = this.brands[0].USERID
        this.$store.commit('SET_BRAND', defaultBrand)
      } else if (this.brands.length === 1) {
        const defaultBrand = this.brands[0].USERID
        this.$store.commit('SET_BRAND', defaultBrand)
      } else {
        this.goToHome()
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store
            .dispatch('login', this.loginForm)
            .then(() => {
              this.shouldShowBrand()
              this.loading = false
            })
            .catch(e => {
              // 如果验证码登错一次后，需要刷新验证码
              if (e.message && e.message.match(/验证码/)) {
                this.refreshCode()
              }
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    handleChangeBrand(brandName) {
      this.$store.commit('SET_BRAND', brandName)
    },
    async handleComfirmBrand() {
      await this.$store.dispatch('chooseBrand', this.choosedBrand)
      this.goToHome()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.login-container {
  min-height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, #68c4f7, #2194d3);
  overflow: hidden;

  .login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 60%;
    top: 50%;
    width: 580px;
    padding: 24px 0;
    border-radius: 4px;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  .division {
    width: 1px;
    height: 320px;
    margin: 0 30px;
    background-color: #fafafa;
  }

  .content-container {
    width: 250px;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .code-container {
    display: flex;
    align-items: center;

    .img-container {
      cursor: pointer;
      display: flex;
      align-content: center;
      margin-left: 24px;

      img {
        display: block;
        width: 100%;
        height: 36px;
        border: 1px solid $border-color;
        border-radius: 4px;
      }
    }
  }

  .title-container {
    position: relative;

    .title {
      font-size: 36px;
      color: #2194d3;
      margin: 0px auto 10px auto;
      text-align: center;
      font-weight: bold;
    }

    .sub-title {
      font-size: 16px;
      color: #8590a6;
      text-align: center;
      margin: 0 0 35px;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 16px;
    color: #889aa4;
    cursor: pointer;

    .pwd-eye {
      fill: currentColor;
      width: 16px;
    }
  }

  .brand-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #8590a6;

    label {
      flex-shrink: 0;
    }
  }

  .thirdparty {
    margin-top: 20px;
  }

  .footer-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 16px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
  }
}

.slide-enter-active {
  transition: all 0.2s ease-in-out;
}

.slide-enter {
  opacity: 0;
  transform: translateX(-20%);
}

.comfirm-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
</style>
